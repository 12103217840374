import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Welcome to Triple 'S' Caterers - a specialist manufacturer of Samosas,
        Sweets and Savouries. At Triple S, we offer both retail and wholesale
        services, as well as a wide range of catering packages for all
        occasions, at competitive prices.
      </Typography>
      <Typography variant="body1" paragraph>
        Our signature Samosas are available in a variety of fillings, so you can
        choose the option which is most suited to your requirements. Our Samosas
        are best presented as appetizers at social events, with our delicious
        tamarind sauce.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
